<template>
  <div class="container_box">
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <Button class="mr15" type="primary" @click="markAsReadBtn">{{ $t('key1001713') }}</Button>
      <p class="tips_text mr15">
        {{ $t('key1001714') }} <span style="color: #2D8CF0;">{{ totalNumberOfMessages }}</span> {{ $t('key1001715') }} <span style="color: #f30606">
        {{ systemAnnouncementUnreadTotal + messageNotificationTotal }}
      </span> {{ $t('key1000768') }}
      </p>
    </div>
    <Tabs v-model="tabStatus" :animated="false" class="tabs_styles">
      <TabPane v-for="item in statusList" :label="handleLable(item)" :name="item.value"></TabPane>
    </Tabs>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        :loading="tableLoading"
        border
        max-height="600"
        :columns="tableColumns"
        @on-selection-change="tableChange"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--查看系统公告弹窗-->
    <viewAnnouncementModal ref="viewAnnouncement" @updateData="pageParamsStatus = true"></viewAnnouncementModal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import viewAnnouncementModal from "@/components/common/viewAnnouncementModal.vue";
import {getMenuRoutingId, isJSON} from "@/utils/common";

export default {
  name: "messageCenter",
  components: {viewAnnouncementModal},
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      pageParams: {
        pageSize: 15,
        pageNum: 1
      },
      total: 0,
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001717'),
          key: 'title',
          align: 'left',
          render(h, params) {
            let informationType = params.row.informationType;
            // hasRead 是否已读 0 未读 1已读 ,
            let hasRead = params.row.hasRead === 0;
            let arr = [
              h('div', {
                class: 'flex align-items-center',
                style: {
                  position: 'relative'
                }
              }, [
                hasRead ? h('span', {
                  class: 'unread_message_styles'
                }) : '',
                h('h3', {
                  style: {
                    fontSize: '14px',
                    color: '#333',
                    marginBottom: '5px'
                  },
                  class: hasRead ? 'font-weight-bold' : ''
                }, params.row.title)
              ]),
              h('p', {
                domProps: {
                  innerHTML: params.row.content
                },
                on: {
                  click: (event) => {
                    event.stopPropagation();
                    // 系统公告
                    if (v.tabStatus === '1') {
                      let noticeInfoId = params.row.noticeInfoId;
                      v.$refs['viewAnnouncement'].getViewAnnouncementData(noticeInfoId);
                    }
                    // 消息通知
                    else {
                      let id = event.target.id || '';
                      if (id) {
                        if (document.getElementById(id)) {
                          let packageCode = document.getElementById(id).innerText;
                          if (packageCode) {
                            switch (informationType) {
                              // 订单取消
                              case 7:
                                localStorage.setItem('activeName', getMenuRoutingId('/supplierOrderList'));
                                v.$store.commit('updateSystemMenu', true);
                                localStorage.setItem('supplierOrderListQuery', JSON.stringify(packageCode));
                                setTimeout(() => {
                                  v.$router.push({
                                    path: '/supplierOrderList',
                                  });
                                }, 500);
                                break;
                              // 货品多发通知
                              case 12:
                                let val = packageCode.split('-');
                                let pickupOrderNumber = val[0];
                                let wmsPickupOrderId = val[1];
                                localStorage.setItem('activeName', getMenuRoutingId('/shipmentRecord'));
                                v.$store.commit('updateSystemMenu', true);
                                setTimeout(() => {
                                  v.$router.push({
                                    path: '/shipmentRecordDetails',
                                    query: {
                                      pickupOrderNumber: pickupOrderNumber,
                                      wmsPickupOrderId: wmsPickupOrderId
                                    }
                                  })
                                }, 500);
                                break;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
            ];
            return h('div', {
                style: {
                  cursor: 'pointer',
                  padding: '8px 0'
                },
                on: {
                  click: () => {
                    if (v.tabStatus === '2') {
                      let ymsStationNotificationId = params.row.ymsStationNotificationId;
                      if (ymsStationNotificationId) {
                        v.viewMessage(params.row);
                      }
                    }
                  }
                }
              },
              arr);
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001718'),
          key: 'time',
          align: 'center',
          sortable: true,
          width: 200,
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.updatedTime, 'fulltime'));
          }
        }
      ],
      tableData: [],
      selectTableData: [],
      statusList: [
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000799'), value: '1', total: 0},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000508'), value: '2', total: 0}
      ],
      tabStatus: '1',
      systemParams: {
        isRead: null, // 是否已读 null全部 0否 1是
        pageNum: 1,
        pageSize: 15,
        subsystemName: 'supplier'
      },
      systemAnnouncementUnreadTotal: 0,
      messageNotificationTotal: 0,
      totalNumberOfMessages: 0
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  methods: {
    // 标记为已读
    markAsReadBtn() {
      let v = this;
      if (v.selectTableData.length > 0) {
        let ids = [];
        v.selectTableData.map((item) => {
          if (v.tabStatus === '2') {
            ids.push(item.ymsStationNotificationId);
          } else {
            ids.push(item.noticeInfoId);
          }
        });
        if (ids.length > 0) {
          if (v.tabStatus === '2') {
            v.axios.post(api.post_ymsSupplierStationNotification_batchRead, ids,
              {loading: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000084'));
                v.selectTableData = [];
                v.pageParamsStatus = true;
                v.getMessageNotificationData(api.get_ymsSupplierStationNotification_lastUnRead);
              }
            });
          } else {
            v.axios.post(api.post_ymsNoticeInfo_disable, ids, {loading: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000084'));
                v.selectTableData = [];
                v.pageParamsStatus = true;
                v.getMessageNotificationData(api.get_ymsSupplierStationNotification_lastUnRead);
              }
            });
          }
        }
      } else {
        v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001719'));
        return false;
      }
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.tableLoading = true;
      this.tableData = [];
      this.total = 0;
      v.axios.post(api.post_ymsSupplierStationNotification_query, v.pageParams).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let newList = data.list || [];
            if (newList.length > 0) {
              newList.map((item) => {
                if (item.informationType === 17) {
                  let obj = {};
                  let content = item.content;
                  if (isJSON(content)) {
                    obj = JSON.parse(content);
                  }
                  item.content = `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000511')}${obj.supplierSpu}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000512')}${obj.shelvedRejectReason}。`
                }
              });
            }
            v.tableData = newList;
            v.total = data.total;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
          }
        } else {
          v.tableLoading = false;
        }
      });
    },
    // 获取未读的个数
    getNumbers() {
      let v = this;
      v.systemAnnouncementUnreadTotal = 0;
      v.messageNotificationTotal = 0;

      // 获取消息通知未读数据
      function getNotification() {
        return new Promise((resolve) => {
          v.axios.get(api.get_ymsSupplierStationNotification_count).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              resolve(data);
            }
          });
        })
      }

      // 获取系统公告未读数据
      function getSystemAnnouncement() {
        return new Promise((resolve) => {
          let query = {
            merchantId: v.userInfo.merchantId,
            subsystemName: 'supplier'
          };
          v.axios.post(api.post_ymsNoticeInfo_queryNoticeInfoCount, query).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              resolve(data);
            }
          });
        })
      }

      Promise.all([getNotification(), getSystemAnnouncement()]).then(result => {
        let notice = result[0];
        let announcement = result[1];
        v.messageNotificationTotal = notice.unReadCount || 0;
        let unReadCount = announcement.filter((item) => {
          return item.isRead === 0
        });
        v.systemAnnouncementUnreadTotal = unReadCount[0].countNum || 0;
        let systemAnnouncementTotal = announcement.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.countNum;
        }, 0);
        v.totalNumberOfMessages = notice.totalCount + systemAnnouncementTotal;
        v.statusList.map((item) => {
          item.value === '1' ? item.total = v.systemAnnouncementUnreadTotal : item.total = v.messageNotificationTotal;
        });
        v.$forceUpdate();
      });
    },
    // 处理消息的数量
    handleLable(data) {
      return data.name + (data.total ? `(${data.total})` : '');
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = data;
    },
    // 查看当前消息
    viewMessage(data) {
      let v = this;
      v.axios.post(api.post_ymsSupplierStationNotification_batchRead, [data.ymsStationNotificationId], {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.$router.push({
            path: '/messageMailboxes',
            query: {
              ymsReceiveStationNotificationLogId: data.ymsReceiveStationNotificationLogId
            }
          });
          v.getMessageNotificationData(api.get_ymsSupplierStationNotification_lastUnRead);
        }
      });
    },
    // 获取系统公告数据
    getSystemAnnouncementData() {
      this.tableData = [];
      this.total = 0;
      this.systemParams.merchantId = this.userInfo.merchantId;
      this.tableLoading = true;
      this.axios.post(api.post_ymsNoticeInfo_queryNewNoticeInfo, this.systemParams).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          let list = data.list || [];
          this.total = data.total;
          if (list.length > 0) {
            list.map((item) => {
              item.hasRead = item.isRead;
              item['content'] = `<span class="themeColor">${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001716')}</span>`
            });
            this.tableData = list;
            this.$nextTick(() => {
              this.tableLoading = false;
            });
          }
        } else {
          this.tableLoading = false;
        }
      });
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getNumbers();
        if (v.tabStatus === '2') {
          v.getList();
        } else {
          v.getSystemAnnouncementData();
        }
        v.pageParamsStatus = false;
      }
    },
    tabStatus: {
      handler(val) {
        this.getNumbers();
        if (val === '1') {
          this.getSystemAnnouncementData();
        } else {
          this.getList();
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.operation_box {
  align-items: center;

  .tips_text {
    color: #333;
    font-size: 17px;
    font-weight: bold;
  }
}

.table_box /deep/ .unread_message_styles {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  top: 5px;
  background-color: #2D8CF0;
}
</style>
